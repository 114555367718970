@media only screen and (max-width: 900px) {
    h1, h2, h3, h4,
    h1 span, h2 span, h4 span{  
        line-height: 12vw;
        font-size: 12vw;
        margin: 0 0 11.1vw 0;
    }    

    ul{
        padding-left: 5vw;
    }

    li {
        padding-left: 5vw;
    }

    h2.small{
        line-height: 10vw;
        font-size: 7.5vw;
        margin-top: 18.7vw;
        margin-bottom: 6vw;
    }

    .listTitle{
        line-height: 7vw;
        font-size: 4vw;
        margin-top: 9.7vw;
        margin-bottom: 3vw;
    }

    .logo{
        margin-left: 0;
    }

    .text, li {
        font-size: 5vw;
        line-height: 7vw;
        margin-bottom: 11.1vw;
    }

    .button{
        height: 12vw;
        font-size: 4vw;
        width: 90%;
        margin: 0 auto;
    }

    h3, h3 span{  
        line-height: 10vw;
        font-size: 9vw;  
    }
      
    h4{
        font-size: 8vw;
        line-height: 9vw;
    }

    .footer-text {
        font-size: 3vw;
    }

    .il_1 {
      width: 125vw;
      height: auto;
      float: right;
      margin-right: -25vw;
    }
  
    .s2h3{
      margin-left: 0;
      margin-top: 64px;
      line-height: 10vw;
      font-size: 9vw;
    }

    .s2h3 span{
        line-height: 10vw;
        font-size: 9vw;
    }
  
    .il_2{
      width: 100vw;
    }
  
    .s3il{
      width: 80vw;
      height: 80vw;
      background: url('../assets/ILLUSTRATION_03.png') no-repeat center center;
      background-size: 100%;
      margin: 20px auto;
    }
  
    .footer-terms{
      position: absolute;
      bottom: 40px;
      width: 100%;
    }

    .getstarted{
        margin-top: -32px;
    }

    .footerIlMob{
        display: inline-block;
    }

    .footerIl{
        display: none;
    }

    .s5h2{
        margin: 11.1vw auto 4.5vw auto;
    }

    .s2h2_2{
        margin-bottom: 0;
    }

    .terms footer{
        margin-top: 20vw;
    }

    #queueMessage .text-purple2,
    #pdfMessage .text-purple2{  
        font-size: 8vw;
        line-height: 9vw;
    }

    .loginMessage{
        margin-top: 80px;
    }
}