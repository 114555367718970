.tokenomics{
    background: linear-gradient(187.77deg, #FFA89B -17.89%, #9630FF 45.4%, #3B2582 95.43%);
}

.tokenomics-content h1{
    font-size: 60px;
    line-height: 56px;
}

.tokenomics-content .slogan, 
.tokenomics-content p,
.tokenomics-content h3{
    font-size: 18px;
    line-height: 24px;
}

.tokenomics-content p{
    margin-top: 10px;
}

.tokenomics-content .obolLogoSection p{
    margin-bottom: 0;
}

.tokenomics-content .slogan{
    margin-bottom: 137px;
}

.tokenomics-content h2{
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
    letter-spacing: 4px;
    margin: 0 0 10px 0;
    padding: 0;   
    }

.tokenomics-content h2.mb15{
    margin-bottom: 15px;
}

.tokenomics-content h3{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.tokenomics-content .obol-subsec{
    margin-bottom: 130px;
}


.tokenomics-content .text-bold{
    font-family: 'Frank Ruhl Libre', serif;
    font-size: 28px;
    line-height: 34px;
    font-weight: 900;
}

.tokenomics-content button{
    margin-top: 40px;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    outline: none;  
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFA89C;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    letter-spacing: 1px;
}

.token-section{
    margin: 250px 0 75px 0;
}

.tokenomics-content.buy-obol{
    margin-top: 215px;
    margin-bottom: 215px;
}

.tokenomicsWrap{
    max-width: 2000px;
    margin: auto;
}

.tokenomics-content .obolBigLogo{
    width: 90%;
    height: auto;
    max-width: 640px;
}

.tokenomics-content .obolMobLogo{
    width: 90%;
    height: auto;
    margin: 0 auto 20px auto;
    display: block;
    max-width: 300px;
}

/* XLarge */
@media only screen and (max-width: 1536px) {
    .tokenomics-content .obol-subsec{
        margin-bottom: 70px;
    }
}

/* Large */
@media only screen and (max-width: 1280px) {
    .tokenomics-content .obol-subsec{
        margin-bottom: 35px;
    }

    .tokenomics-content .obolLogoSection h3,
    .tokenomics-content .obolLogoSection p {
        font-size: 16px;
    }    
}

/* Medium */
@media only screen and (max-width: 1023px) {
    .tokenomics-content h1{
        margin-bottom: 3vw;
    }

    .tokenomics-content .slogan{
        margin-bottom: 15vw;
    }

    .tokenomics-content button{
        margin: 35px 0 35px 0;
    }

    .token-section{
        margin-top: 15vw;
    }

    .tokenomics-content.buy-obol{
        margin-top: 15vw;
        margin-bottom: 15vw;
    }

    .tokenomics-content h1,
    .tokenomics-content h1 span{
        font-size: 50px;
        line-height: 46px;
    }

    .obolLogoSection .text-right{
        text-align: left !important;
    }

    .tokenomics-content .obolLogoSection h3,
    .tokenomics-content .obolLogoSection p {
        font-size: 18px;
    }
}

/* Mobile */
@media only screen and (max-width: 480px) {
    .tokenomics-content h2.mb15{
        margin-bottom: 10px;
    }

    .token-section,
    .tokenomics-content.buy-obol {
        margin: 80px 0 30px 0;
    }

    .tokenomics-content.buy-obol{
        margin-bottom: 130px;
    }

    .tokenomics-content .obolMobLogo{
        width: 90%;
        height: auto;
        max-width: none;
        margin-bottom: 30px;
    }

    .tokenomics-content .obol-subsec,
    .tokenomics-content .text-right{
        margin-bottom: 30px;
    }

    .tokenomics-content h1{
        font-size: 50px;
        line-height: 46px;
        margin-bottom: 20px;
        text-align: left;
    }

    .tokenomics-content .slogan{
        text-align: left;
        margin-bottom: 80px;
    }

    .tokenomics-content button{
        margin-top: 35px;
        margin-bottom: 30px;
    }

    .tokenomics-content .obolLogoSection h3,
    .tokenomics-content .obolLogoSection p {
        font-size: 18px;
    }
}