@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@400;900&family=Lato:wght@400;700&display=swap');

body{   
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: #27204d !important;   
}

body *{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #F6F6F6;  
}

/* main{
  max-width: 1536px;
  margin: 0 auto;
} */

.mainSection{
  overflow: hidden;
  background: rgb(177,80,237);
  background: no-repeat linear-gradient(153deg, rgba(177,77,239,0.7) 0%, rgba(244,164,174,0.8) 25%, rgba(108,126,253,0.8) 55%, rgba(49,46,105,1) 95%); 
}

.mainSection.terms{
  background: #27204D;
}

h1, h2, h3, h4,
h1 span, h2 span, h3 span, h4 span, .quote {
  font-family: 'Frank Ruhl Libre', serif;
  font-weight: 900;
  line-height: 46px;
  font-size: 50px;
  margin: 0 0 40px 0;
}

h3{  
  line-height: 38px;
  font-size: 35px;  
}

h4{
  font-size: 28px;
  line-height: 34px;
}

ul{
  list-style-image: url('../assets/li.svg');
  padding-left: 20px;
}

li {
  padding-left: 20px;
}

h2.small {
  font-size: 25px;
  margin-top: 70px;
  margin-bottom: 10px;
}

.logo{
  margin: 32px 0 16px 16px;
}

.text, li{  
  font-size: 18px;
  line-height: 24px;  
  margin: 0 0 40px 0;
}

.terms .text, .terms li{
  margin-bottom: 20px;
} 

.text-bold {  
  font-weight: 700;
}

.text-purple{
  color: #9630FF;
}

.text-purple2{
  color: #D642FF;
}

.text-peach{
  color: #FFA89C;
}

.il_1{            
  width: 100%;
  height: auto;
  float: right;
  margin-right: -25%;  
}

.il_2{  
  position: relative;
  top: 0;
  left: 0;
  width: 80%;
  height: auto;
}

.s2h2_2{
  padding-right: 18px;
}

.button {
  box-sizing: border-box;
  height: 45px;
  width: 80%;
  border: 2px solid #D642FF;
  background: linear-gradient(214.41deg, #cd3fff 0%, #D642FF 30.71%, #9630FF 100%);
  box-shadow: 0 2px 26px -2px rgba(246,246,246,0.23);  
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 2px;  
  display: block;
}

.getstarted{
  border: 2px solid #7585FF;
  background: linear-gradient(196.76deg, #ADBBFF 0%, #7585FF 100%);
  box-shadow: 0 2px 26px -2px rgba(246,246,246,0.23);  
}

.learnnow, .getstarted{
  margin: 40px auto;
  width: 100%;
}

.s2h3{
  margin-left: -98px;
  font-size: 45px;
  line-height: 48px;
}

.s2h3 span{
  font-size: 45px;
  line-height: 48px;
}

.s3h2, .s5h2{
  text-align: center;  
  margin: 40px auto 0 auto;
}

.s5h2{
  margin-bottom: 40px;
}

.s3il{
  position: relative;
  /* background: url('./assets/ILLUSTRATION_03.png') no-repeat center center; */
  /* background-size: 100%; */
  /* width: 40vw;
  height: 40vw;
  margin: 20px auto; */

  /* width: 90%;
  height: 27vw;
  margin: 20px auto;
  min-height: 950px; */
}


.s3il img{
  width: 90%;
  height: auto;
  margin: 0 auto;
  display: block;
}


.s4il{
  width: 100%;
  height: auto;    
}

.footerIl, .footerIlMob{
  width: 100%;
  height: auto;
}

.footerIlMob{
  display: none;
}


.s3span1, .s3span2, .s3span3{  
  font-size: 18px;
}

.s3span1{
  position: absolute;
  width: 150px;
  top: 50%;
  left: -100px;
  text-align: right;
  margin-top: -40px;
}

.s3span2{
  position: absolute;
  width: 165px;
  text-align: left;
  top: 47%;
  margin-top: 32%;
  left: 80%;
}

.s3span3{
  position: absolute;
  width: 180px;
  top: 54%;
  left: 80%;
  text-align: left;
  margin-top: -40%;
}

.text-center{
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right{
  text-align: right;
}


.footer-text{
  font-size: 12px;  
}

.footer-terms{
  position: absolute;
  bottom: 50px;
  width: 100%;
}

.wrapFourSection{
  position: relative;
}

footer{
  position: relative;
}

footer a{
  text-decoration: none;
  padding: 0 20px 0 0;
}

.sectionThree{
  max-width: 1536px;
  margin: 0 auto;
}

.sectionFourDesc{
  background: url("../assets/ILLUSTRATION_04.svg") no-repeat 0 0 transparent;
  background-size: 100%;
  padding-top: 28%;
  padding-bottom: 10%;  
}

.sectionFourDesc h4{
  text-align: right;
}

.sectionFourDesc .s4t1,
.sectionFourDesc .s4t3{
  padding-top: 200px;
}

.sectionFourDesc .s4t1 span,
.sectionFourDesc .s4t2 span,
.sectionFourDesc .s4t3 span,
.sectionFourDesc .s4t4 span{
  margin-bottom: 18px;
  display: inline-block;
}

.listTitle{
  line-height: 28px;
  font-size: 30px;
}

.sectionFive{
  max-width: 1536px;
  margin: 0 auto;
}

.loginMessage{
  margin-bottom: 80px;
}

#loginForm .text-login,
#confirmMessage .text{
  font-size: 16px;
  line-height: 20px;
}

#loginForm h4,
#confirmMessage h4{
  margin-bottom: 16px;  
}

#loginForm button{
  margin:0 auto;
  width: 100%;
  text-align: center;
  background: transparent;
  border: none;
  outline: none;
  margin-top: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFA89C;
  text-decoration: underline;
  cursor: pointer;
}

.loginMessage img{  
  display: block;
  margin: 0 auto;  
}

.loginMessage img.keyHole,
.loginMessage img.dropIcon{
  margin: 40px auto;
}

#pdfMessage img{
  margin-bottom: 30px;
}

#queueMessage img{
  padding-left: 30px;
}

#loginForm .net-err{
  margin-top: 18px;
}

#queueMessage .text-purple2,
#pdfMessage .text-purple2{  
  font-size: 28px;
  line-height: 34px;
}

#pdfMessage .button{
  margin: 0 auto 30px auto;
}

.quote {
  margin-top: 50px;
  opacity: .75;
}