@import url(https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@400;900&family=Lato:wght@400;700&display=swap);
body{   
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: #27204d !important;   
}

body *{
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #F6F6F6;  
}

/* main{
  max-width: 1536px;
  margin: 0 auto;
} */

.mainSection{
  overflow: hidden;
  background: rgb(177,80,237);
  background: no-repeat linear-gradient(153deg, rgba(177,77,239,0.7) 0%, rgba(244,164,174,0.8) 25%, rgba(108,126,253,0.8) 55%, rgba(49,46,105,1) 95%); 
}

.mainSection.terms{
  background: #27204D;
}

h1, h2, h3, h4,
h1 span, h2 span, h3 span, h4 span, .quote {
  font-family: 'Frank Ruhl Libre', serif;
  font-weight: 900;
  line-height: 46px;
  font-size: 50px;
  margin: 0 0 40px 0;
}

h3{  
  line-height: 38px;
  font-size: 35px;  
}

h4{
  font-size: 28px;
  line-height: 34px;
}

ul{
  list-style-image: url(/static/media/li.64126ca9.svg);
  padding-left: 20px;
}

li {
  padding-left: 20px;
}

h2.small {
  font-size: 25px;
  margin-top: 70px;
  margin-bottom: 10px;
}

.logo{
  margin: 32px 0 16px 16px;
}

.text, li{  
  font-size: 18px;
  line-height: 24px;  
  margin: 0 0 40px 0;
}

.terms .text, .terms li{
  margin-bottom: 20px;
} 

.text-bold {  
  font-weight: 700;
}

.text-purple{
  color: #9630FF;
}

.text-purple2{
  color: #D642FF;
}

.text-peach{
  color: #FFA89C;
}

.il_1{            
  width: 100%;
  height: auto;
  float: right;
  margin-right: -25%;  
}

.il_2{  
  position: relative;
  top: 0;
  left: 0;
  width: 80%;
  height: auto;
}

.s2h2_2{
  padding-right: 18px;
}

.button {
  box-sizing: border-box;
  height: 45px;
  width: 80%;
  border: 2px solid #D642FF;
  background: linear-gradient(214.41deg, #cd3fff 0%, #D642FF 30.71%, #9630FF 100%);
  box-shadow: 0 2px 26px -2px rgba(246,246,246,0.23);  
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 2px;  
  display: block;
}

.getstarted{
  border: 2px solid #7585FF;
  background: linear-gradient(196.76deg, #ADBBFF 0%, #7585FF 100%);
  box-shadow: 0 2px 26px -2px rgba(246,246,246,0.23);  
}

.learnnow, .getstarted{
  margin: 40px auto;
  width: 100%;
}

.s2h3{
  margin-left: -98px;
  font-size: 45px;
  line-height: 48px;
}

.s2h3 span{
  font-size: 45px;
  line-height: 48px;
}

.s3h2, .s5h2{
  text-align: center;  
  margin: 40px auto 0 auto;
}

.s5h2{
  margin-bottom: 40px;
}

.s3il{
  position: relative;
  /* background: url('./assets/ILLUSTRATION_03.png') no-repeat center center; */
  /* background-size: 100%; */
  /* width: 40vw;
  height: 40vw;
  margin: 20px auto; */

  /* width: 90%;
  height: 27vw;
  margin: 20px auto;
  min-height: 950px; */
}


.s3il img{
  width: 90%;
  height: auto;
  margin: 0 auto;
  display: block;
}


.s4il{
  width: 100%;
  height: auto;    
}

.footerIl, .footerIlMob{
  width: 100%;
  height: auto;
}

.footerIlMob{
  display: none;
}


.s3span1, .s3span2, .s3span3{  
  font-size: 18px;
}

.s3span1{
  position: absolute;
  width: 150px;
  top: 50%;
  left: -100px;
  text-align: right;
  margin-top: -40px;
}

.s3span2{
  position: absolute;
  width: 165px;
  text-align: left;
  top: 47%;
  margin-top: 32%;
  left: 80%;
}

.s3span3{
  position: absolute;
  width: 180px;
  top: 54%;
  left: 80%;
  text-align: left;
  margin-top: -40%;
}

.text-center{
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right{
  text-align: right;
}


.footer-text{
  font-size: 12px;  
}

.footer-terms{
  position: absolute;
  bottom: 50px;
  width: 100%;
}

.wrapFourSection{
  position: relative;
}

footer{
  position: relative;
}

footer a{
  text-decoration: none;
  padding: 0 20px 0 0;
}

.sectionThree{
  max-width: 1536px;
  margin: 0 auto;
}

.sectionFourDesc{
  background: url(/static/media/ILLUSTRATION_04.47fff35e.svg) no-repeat 0 0 transparent;
  background-size: 100%;
  padding-top: 28%;
  padding-bottom: 10%;  
}

.sectionFourDesc h4{
  text-align: right;
}

.sectionFourDesc .s4t1,
.sectionFourDesc .s4t3{
  padding-top: 200px;
}

.sectionFourDesc .s4t1 span,
.sectionFourDesc .s4t2 span,
.sectionFourDesc .s4t3 span,
.sectionFourDesc .s4t4 span{
  margin-bottom: 18px;
  display: inline-block;
}

.listTitle{
  line-height: 28px;
  font-size: 30px;
}

.sectionFive{
  max-width: 1536px;
  margin: 0 auto;
}

.loginMessage{
  margin-bottom: 80px;
}

#loginForm .text-login,
#confirmMessage .text{
  font-size: 16px;
  line-height: 20px;
}

#loginForm h4,
#confirmMessage h4{
  margin-bottom: 16px;  
}

#loginForm button{
  margin:0 auto;
  width: 100%;
  text-align: center;
  background: transparent;
  border: none;
  outline: none;
  margin-top: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFA89C;
  text-decoration: underline;
  cursor: pointer;
}

.loginMessage img{  
  display: block;
  margin: 0 auto;  
}

.loginMessage img.keyHole,
.loginMessage img.dropIcon{
  margin: 40px auto;
}

#pdfMessage img{
  margin-bottom: 30px;
}

#queueMessage img{
  padding-left: 30px;
}

#loginForm .net-err{
  margin-top: 18px;
}

#queueMessage .text-purple2,
#pdfMessage .text-purple2{  
  font-size: 28px;
  line-height: 34px;
}

#pdfMessage .button{
  margin: 0 auto 30px auto;
}

.quote {
  margin-top: 50px;
  opacity: .75;
}
@media only screen and (max-width: 900px) {
    h1, h2, h3, h4,
    h1 span, h2 span, h4 span{  
        line-height: 12vw;
        font-size: 12vw;
        margin: 0 0 11.1vw 0;
    }    

    ul{
        padding-left: 5vw;
    }

    li {
        padding-left: 5vw;
    }

    h2.small{
        line-height: 10vw;
        font-size: 7.5vw;
        margin-top: 18.7vw;
        margin-bottom: 6vw;
    }

    .listTitle{
        line-height: 7vw;
        font-size: 4vw;
        margin-top: 9.7vw;
        margin-bottom: 3vw;
    }

    .logo{
        margin-left: 0;
    }

    .text, li {
        font-size: 5vw;
        line-height: 7vw;
        margin-bottom: 11.1vw;
    }

    .button{
        height: 12vw;
        font-size: 4vw;
        width: 90%;
        margin: 0 auto;
    }

    h3, h3 span{  
        line-height: 10vw;
        font-size: 9vw;  
    }
      
    h4{
        font-size: 8vw;
        line-height: 9vw;
    }

    .footer-text {
        font-size: 3vw;
    }

    .il_1 {
      width: 125vw;
      height: auto;
      float: right;
      margin-right: -25vw;
    }
  
    .s2h3{
      margin-left: 0;
      margin-top: 64px;
      line-height: 10vw;
      font-size: 9vw;
    }

    .s2h3 span{
        line-height: 10vw;
        font-size: 9vw;
    }
  
    .il_2{
      width: 100vw;
    }
  
    .s3il{
      width: 80vw;
      height: 80vw;
      background: url(/static/media/ILLUSTRATION_03.05a3da66.png) no-repeat center center;
      background-size: 100%;
      margin: 20px auto;
    }
  
    .footer-terms{
      position: absolute;
      bottom: 40px;
      width: 100%;
    }

    .getstarted{
        margin-top: -32px;
    }

    .footerIlMob{
        display: inline-block;
    }

    .footerIl{
        display: none;
    }

    .s5h2{
        margin: 11.1vw auto 4.5vw auto;
    }

    .s2h2_2{
        margin-bottom: 0;
    }

    .terms footer{
        margin-top: 20vw;
    }

    #queueMessage .text-purple2,
    #pdfMessage .text-purple2{  
        font-size: 8vw;
        line-height: 9vw;
    }

    .loginMessage{
        margin-top: 80px;
    }
}
.tokenomics{
    background: linear-gradient(187.77deg, #FFA89B -17.89%, #9630FF 45.4%, #3B2582 95.43%);
}

.tokenomics-content h1{
    font-size: 60px;
    line-height: 56px;
}

.tokenomics-content .slogan, 
.tokenomics-content p,
.tokenomics-content h3{
    font-size: 18px;
    line-height: 24px;
}

.tokenomics-content p{
    margin-top: 10px;
}

.tokenomics-content .obolLogoSection p{
    margin-bottom: 0;
}

.tokenomics-content .slogan{
    margin-bottom: 137px;
}

.tokenomics-content h2{
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
    letter-spacing: 4px;
    margin: 0 0 10px 0;
    padding: 0;   
    }

.tokenomics-content h2.mb15{
    margin-bottom: 15px;
}

.tokenomics-content h3{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.tokenomics-content .obol-subsec{
    margin-bottom: 130px;
}


.tokenomics-content .text-bold{
    font-family: 'Frank Ruhl Libre', serif;
    font-size: 28px;
    line-height: 34px;
    font-weight: 900;
}

.tokenomics-content button{
    margin-top: 40px;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    outline: none;  
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFA89C;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    letter-spacing: 1px;
}

.token-section{
    margin: 250px 0 75px 0;
}

.tokenomics-content.buy-obol{
    margin-top: 215px;
    margin-bottom: 215px;
}

.tokenomicsWrap{
    max-width: 2000px;
    margin: auto;
}

.tokenomics-content .obolBigLogo{
    width: 90%;
    height: auto;
    max-width: 640px;
}

.tokenomics-content .obolMobLogo{
    width: 90%;
    height: auto;
    margin: 0 auto 20px auto;
    display: block;
    max-width: 300px;
}

/* XLarge */
@media only screen and (max-width: 1536px) {
    .tokenomics-content .obol-subsec{
        margin-bottom: 70px;
    }
}

/* Large */
@media only screen and (max-width: 1280px) {
    .tokenomics-content .obol-subsec{
        margin-bottom: 35px;
    }

    .tokenomics-content .obolLogoSection h3,
    .tokenomics-content .obolLogoSection p {
        font-size: 16px;
    }    
}

/* Medium */
@media only screen and (max-width: 1023px) {
    .tokenomics-content h1{
        margin-bottom: 3vw;
    }

    .tokenomics-content .slogan{
        margin-bottom: 15vw;
    }

    .tokenomics-content button{
        margin: 35px 0 35px 0;
    }

    .token-section{
        margin-top: 15vw;
    }

    .tokenomics-content.buy-obol{
        margin-top: 15vw;
        margin-bottom: 15vw;
    }

    .tokenomics-content h1,
    .tokenomics-content h1 span{
        font-size: 50px;
        line-height: 46px;
    }

    .obolLogoSection .text-right{
        text-align: left !important;
    }

    .tokenomics-content .obolLogoSection h3,
    .tokenomics-content .obolLogoSection p {
        font-size: 18px;
    }
}

/* Mobile */
@media only screen and (max-width: 480px) {
    .tokenomics-content h2.mb15{
        margin-bottom: 10px;
    }

    .token-section,
    .tokenomics-content.buy-obol {
        margin: 80px 0 30px 0;
    }

    .tokenomics-content.buy-obol{
        margin-bottom: 130px;
    }

    .tokenomics-content .obolMobLogo{
        width: 90%;
        height: auto;
        max-width: none;
        margin-bottom: 30px;
    }

    .tokenomics-content .obol-subsec,
    .tokenomics-content .text-right{
        margin-bottom: 30px;
    }

    .tokenomics-content h1{
        font-size: 50px;
        line-height: 46px;
        margin-bottom: 20px;
        text-align: left;
    }

    .tokenomics-content .slogan{
        text-align: left;
        margin-bottom: 80px;
    }

    .tokenomics-content button{
        margin-top: 35px;
        margin-bottom: 30px;
    }

    .tokenomics-content .obolLogoSection h3,
    .tokenomics-content .obolLogoSection p {
        font-size: 18px;
    }
}
